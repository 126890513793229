import React, { Component } from "react";
import "./css/CustomSelect.css";
import PropTypes from "prop-types";
/**
 * O componente de select padrão do IAPP
 *
 * @export
 * @class CustomSelect
 * @extends {Component}
 */
export default class CustomSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({ nome: PropTypes.string, selected: PropTypes.bool })
    )
  };

  render() {
    return (
      <select
        className={`form-control ${this.props.className}`}
        name={this.props.name}
        onChange={(e) => this.props.onChange(e)}
        required={this.props.required}
        disabled={this.props.disabled}
        style={{ fontSize: 12, ...this.props.style }}
      >
        {this.props.options.map((option, key) => (
          <option key={key} value={option.valor || key} selected={option.selected} disabled={option.disabled}>
            {option.nome}
          </option>
        ))}
      </select>
    );
  }
}
