import React, { useState, useEffect } from 'react';
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './css/Question.css';

export const QuestionFeedback = function({ number, enunciate, ansOnChange, editorInitialState }) {
  const [ editorState, setEditorState ] = useState(editorInitialState)
  const [currHtmlLength, setCurrHtmlLenght] = useState(0)

  /**
   * componentDidMount do question de
   */
  useEffect(() => {
    ansOnChange(convertToRaw(editorInitialState.getCurrentContent()))
    return () => {}
  }, [])

  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '').replace(/(?:\r\n|\r|\n)/g, '').replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"').replace(/&nbsp;/g, " ");
  }

  function handleBeforeInput () {
    if (currHtmlLength > 250) {
      return 'handled';
    }
  }

  return (
    <Col sm="12" style={{  }}>
      <Row>
        <Col sm="12" style={{ display: 'flex', alignItems: 'center' }}>
          <p><b>3. Observações adicionais</b></p>
          <Tooltip
            title="Deixe comentários para seu avaliado. O que você escrever nesta área será apresentado para o participante avaliado."
            size="small"
            position="top"
            arrow
          ><FaRegQuestionCircle style={{ marginLeft: 5, marginBottom: 1 }} size={12} /></Tooltip>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Editor
            spellCheck={true}
            stripPastedStyles={true}
            defaultEditorState={editorState}
            onChange={newEditorState => {
              const html = draftToHtml(editorState);
              const htmlLength = removeTags(html).length;

              setCurrHtmlLenght(htmlLength)
              setEditorState(newEditorState);
              ansOnChange(newEditorState) }}
            handleBeforeInput={handleBeforeInput}
            wrapperClassName='wrapper-historia-de-vida'
            editorClassName='editor-historia-de-vida'
            toolbar={{
              options: ["inline"],
              inline: {
                inDropdown: false,
                options: ["bold", "italic", "underline"],
              },
            }}/>
        </Col>
      </Row>
    </Col>
  )
}

QuestionFeedback.propTypes = {
  number: PropTypes.string,
  enunciate: PropTypes.string.isRequired,
  editorInitialState: PropTypes.object,
  ansOnChange: PropTypes.func.isRequired
}

