import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import { upperCaseFirstLetter } from "../../../../services/utilsFn";
import { DetalhesDoInstrumento } from "./styles";
import { handleIcon } from "../../../pages/Instruments/Components/InstrumentIcons";
import {capitalizeFirstLetterEachWord} from '../../../../utils/masks';

export default class ItemInstrumentoDashboard extends Component {
  static propTypes = {
    instrumento: PropTypes.object.isRequired
  };

  render() {
    const { instrumento } = this.props;

    return (
      <>
        <div>
          {
            handleIcon(instrumento._id)
          }
        </div>
        <DetalhesDoInstrumento sm="8">
          <Row>
            <Col>
              <strong>
                {instrumento.name && instrumento.name.toLowerCase().includes('vida') ? `Análise Argumentativa ${instrumento.title ? `- ${instrumento.title}` : ''}` : upperCaseFirstLetter(instrumento.name)}
              </strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Válido até {instrumento.datas.dataFim}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Participante: {capitalizeFirstLetterEachWord(instrumento.nomeUser.toLowerCase())}
              </span>
            </Col>
          </Row>
        </DetalhesDoInstrumento>
      </>
    );
  }
}
