import React from "react";
import { FaBrain, FaUser } from "react-icons/fa";
import { ASYNCHRONOUS_INSTRUMENTS_IDS } from "../../../../constants/instruments-ids";

// Icones dos instrumentos do módulo assíncrono

export const ICONS = {
  TiposDePersonalidadeIcon: () => <FaBrain size={36}></FaBrain>
};

export function handleIcon(id, fill = "#23282c", size = 40) {
  switch (id) {
    case ASYNCHRONOUS_INSTRUMENTS_IDS.TiposDePersonalidade:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M4 6H2v14a2 2 0 0 0 2 2h14v-2H4V6m16-4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12m-3 5a3 3 0 0 0-3-3a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3m-9 8v1h12v-1c0-2-4-3.1-6-3.1S8 13 8 15z"
              fill={fill}
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.AutoconceitoNoTrabalho:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill="none">
              <path
                d="M11 15c0-.35.06-.687.17-1H4.253a2.249 2.249 0 0 0-2.249 2.249v.92c0 .572.179 1.13.51 1.596C4.057 20.929 6.58 22 10 22c.397 0 .783-.014 1.156-.043A2.997 2.997 0 0 1 11 21v-6zM10 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10zM12 15a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-6zm2.5 1a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6z"
                fill={fill}
              />
            </g>
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.EstiloDeComunicacao:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M23 26a1 1 0 0 1-1 1H8c-.22 0-.43.2-.61.33L4 30V14a1 1 0 0 1 1-1h3.86v-2H5a3 3 0 0 0-3 3v18a1 1 0 0 0 .56.89a1 1 0 0 0 1-.1L8.71 29h13.44A2.77 2.77 0 0 0 25 26.13V25h-2z"
              class="clr-i-outline clr-i-outline-path-1"
              fill={fill}
            />
            <path
              d="M31 4H14a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h13.55l4.78 3.71a1 1 0 0 0 1 .11a1 1 0 0 0 .57-.9V7A3 3 0 0 0 31 4zm1 18.94l-3.5-2.73a1 1 0 0 0-.61-.21H14a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h17a1.1 1.1 0 0 1 1 1.06z"
              class="clr-i-outline clr-i-outline-path-2"
              fill={fill}
            />
            <rect x="0" y="0" width="36" height="36" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.AncorasDaCarreira:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M11 10H5L3 8l2-2h6V3l1-1l1 1v1h6l2 2l-2 2h-6v2h6l2 2l-2 2h-6v6a2 2 0 0 1 2 2H9a2 2 0 0 1 2-2V10z"
              fill={fill}
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.Autoeficacia:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill={fill}>
              <path
                fill-rule="evenodd"
                d="M1 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1H1zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6zm9.854-2.854a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
              />
            </g>
            <rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.EscutaAtiva:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M17 20c-.29 0-.56-.06-.76-.15c-.71-.37-1.21-.88-1.71-2.38c-.51-1.56-1.47-2.29-2.39-3c-.79-.61-1.61-1.24-2.32-2.53C9.29 10.98 9 9.93 9 9c0-2.8 2.2-5 5-5s5 2.2 5 5h2c0-3.93-3.07-7-7-7S7 5.07 7 9c0 1.26.38 2.65 1.07 3.9c.91 1.65 1.98 2.48 2.85 3.15c.81.62 1.39 1.07 1.71 2.05c.6 1.82 1.37 2.84 2.73 3.55A3.999 3.999 0 0 0 21 18h-2c0 1.1-.9 2-2 2zM7.64 2.64L6.22 1.22C4.23 3.21 3 5.96 3 9s1.23 5.79 3.22 7.78l1.41-1.41C6.01 13.74 5 11.49 5 9s1.01-4.74 2.64-6.36zM11.5 9a2.5 2.5 0 0 0 5 0a2.5 2.5 0 0 0-5 0z"
              fill={fill}
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.AspectosDeIdentidade:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M8 9a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m4 8H4v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1m8-9h-6v2h6V8m0 4h-6v2h6v-2m0 4h-6v2h6v-2m2-12h-8v2h8v14H2V6h8V4H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m-9 2h-2V2h2v4z"
              fill={fill}
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.ColetaDeFeedback:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill="none">
              <path
                d="M11.91 14h7.843a2.25 2.25 0 0 1 2.25 2.25v.905A3.75 3.75 0 0 1 20.696 20C19.13 21.344 16.89 22 14 22h-.179a1.752 1.752 0 0 0-.221-1.897l-.111-.12l-2.23-2.224a5.482 5.482 0 0 0 .65-3.76zM6.5 10.5a4.5 4.5 0 0 1 3.46 7.376l2.823 2.814a.75.75 0 0 1-.975 1.135l-.085-.073l-2.903-2.896A4.5 4.5 0 1 1 6.5 10.5zm0 1.5a3 3 0 1 0 0 6a3 3 0 0 0 0-6zM14 2.004a5 5 0 1 1 0 10a5 5 0 0 1 0-10z"
                fill={fill}
              />
            </g>
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.Feedback:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill="none">
              <path
                d="M11.91 14h7.843a2.25 2.25 0 0 1 2.25 2.25v.905A3.75 3.75 0 0 1 20.696 20C19.13 21.344 16.89 22 14 22h-.179a1.752 1.752 0 0 0-.221-1.897l-.111-.12l-2.23-2.224a5.482 5.482 0 0 0 .65-3.76zM6.5 10.5a4.5 4.5 0 0 1 3.46 7.376l2.823 2.814a.75.75 0 0 1-.975 1.135l-.085-.073l-2.903-2.896A4.5 4.5 0 1 1 6.5 10.5zm0 1.5a3 3 0 1 0 0 6a3 3 0 0 0 0-6zM14 2.004a5 5 0 1 1 0 10a5 5 0 0 1 0-10z"
                fill={fill}
              />
            </g>
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.EstilosDeLideranca:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M12 5.5A3.5 3.5 0 0 1 15.5 9a3.5 3.5 0 0 1-3.5 3.5A3.5 3.5 0 0 1 8.5 9A3.5 3.5 0 0 1 12 5.5M5 8c.56 0 1.08.15 1.53.42c-.15 1.43.27 2.85 1.13 3.96C7.16 13.34 6.16 14 5 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3m14 0a3 3 0 0 1 3 3a3 3 0 0 1-3 3c-1.16 0-2.16-.66-2.66-1.62a5.536 5.536 0 0 0 1.13-3.96c.45-.27.97-.42 1.53-.42M5.5 18.25c0-2.07 2.91-3.75 6.5-3.75s6.5 1.68 6.5 3.75V20h-13v-1.75M0 20v-1.5c0-1.39 1.89-2.56 4.45-2.9c-.59.68-.95 1.62-.95 2.65V20H0m24 0h-3.5v-1.75c0-1.03-.36-1.97-.95-2.65c2.56.34 4.45 1.51 4.45 2.9V20z"
              fill={fill}
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.ArteDaArticulacao:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill="none">
              <path
                d="M20.244 12.997c.71 0 1.023.893.47 1.336l-4.719 3.777v1.645a2.25 2.25 0 0 1-2.25 2.25H10.25A2.25 2.25 0 0 1 8 19.755V18.11l-4.72-3.777c-.553-.443-.24-1.336.469-1.336h16.495zm-2.136 1.5H5.886l3.333 2.668a.75.75 0 0 1 .281.585v2.005c0 .414.336.75.75.75h3.495a.75.75 0 0 0 .75-.75V17.75a.75.75 0 0 1 .282-.585l3.33-2.668zM8.75 9h6.495a1.75 1.75 0 0 1 1.744 1.607l.006.143V12h-1.5v-1.25a.25.25 0 0 0-.193-.243l-.057-.007H8.75a.25.25 0 0 0-.243.193l-.007.057V12H7v-1.25a1.75 1.75 0 0 1 1.606-1.744L8.75 9h6.495H8.75zM12 2a3 3 0 1 1 0 6a3 3 0 0 1 0-6zm0 1.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z"
                fill={fill}
              />
            </g>
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.HistoriaDeVida:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 32 32"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M6 4c-1.094 0-2 .906-2 2c0 1.094.906 2 2 2c1.094 0 2-.906 2-2c0-1.094-.906-2-2-2zm4 1c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm2 2c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm-11 3c-1.652 0-3 1.348-3 3c0 1.25 1.613 4.492 2.11 5.457l.89 1.73l.89-1.73C17.388 17.492 19 14.25 19 13c0-1.652-1.348-3-3-3zm11 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm-11 2c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1zm11 1c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm0 3c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zM7 18c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm6 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zM5 20c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm0 3c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm21 1c-1.094 0-2 .906-2 2c0 1.094.906 2 2 2c1.094 0 2-.906 2-2c0-1.094-.906-2-2-2zM7 25c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1zm3 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1z"
              fill={fill}
            />
            <rect x="0" y="0" width="32" height="32" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.ProjetosAtuais:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1024 1024"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <path
              d="M896 1024H128q-53 0-90.5-37.5T0 896V256q0-53 37.5-90.5T128 128h64l64-96Q274 0 384 0h256q110 0 128 32l64 96h64q53 0 90.5 37.5T1024 256v640q0 53-37.5 90.5T896 1024zM320 64l-64 96l96 96l96-128zm81 237q-25 19-49 19q-32 0-64-32l-64-64q-13-13-22-32h-74q-26 0-45 18.5T64 256v640q0 27 18.5 45.5T128 960h192l129-617zM704 64l-128 64l96 128l96-96zm256 192q0-27-19-45.5T896 192h-75q-8 19-21 32l-64 64q-32 32-64 32q-24 0-49-19l-49 43l130 616h192q26 0 45-18.5t19-45.5V256z"
              fill={fill}
            />
            <rect x="0" y="0" width="40" height="40" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    case ASYNCHRONOUS_INSTRUMENTS_IDS.LocusdeControle:
      return (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            style={{
              "-ms-transform": "rotate(360deg)",
              "-webkit-transform": "rotate(360deg)",
              transform: "rotate(360deg)"
            }}
          >
            <g fill={fill}>
              <path
                fill-rule="evenodd"
                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"
              />
              <path
                fill-rule="evenodd"
                d="M3 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1H3zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6z"
              />
            </g>
            <rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      );
    default:
      return <FaUser size={size} color={fill}></FaUser>;
  }
}
