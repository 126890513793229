import React from 'react'
import { Row, Col } from 'reactstrap';
import CustomSelect from './CustomSelect/CustomSelect';
import SearchableSelect from './SearchableSelect/SearchableSelect';
import CampoTextArea from './CampoTextArea/CampoTextArea';

function QuestionPointsFeedback(props) {
  return (
      <Col sm="12">
      <Row>
        <Col sm="12">
          <p><b>{props.title}*</b></p>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col sm="4">
          <p><b>{props.section}*</b></p>
        </Col>
        <Col sm="8">
          <p><b>Explique sua escolha (máximo 250 caracteres)*</b></p>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col sm="4">
        <SearchableSelect
          pontos={props.pontos}
          index={0}
          style={{ width: '100%' }}
          onChange={(value)=>{
            props.handleSelectChange(value, 0)
          }}
          onAdd={async (value) => {
            const res = await props.onAdd(value, props.type)

            return res
          }}
          disabled={false}
          options={props.options}
          toggleDescriptionModal={props.toggleDescriptionModal}
        />
        </Col>
        <Col sm="8">
        <CampoTextArea
          className='feedback-text-area'
          name='pontos-text-1'
          onChange={e => props.handleTextChange(e, 0)}
          value={props.pontos[0].text}
          disabled={false}
        />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col sm="4">
        <SearchableSelect
          pontos={props.pontos}
          toggleDescriptionModal={props.toggleDescriptionModal}
          index={1}
          style={{ width: '100%' }}
          name={'pontos-2'}
          onChange={(e)=>{
            props.handleSelectChange(e, 1)
          }}
          disabled={false}
          onAdd={async (value) => {
            const res = await props.onAdd(value, props.type)

            return res
          }}
          options={props.options}
        />
        </Col>
        <Col sm="8">
          <CampoTextArea
            className='feedback-text-area'
            name='pontos-text-2'
            onChange={e => props.handleTextChange(e, 1)}
            value={props.pontos[1].text}
            disabled={false}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col sm="4">
        <SearchableSelect
          pontos={props.pontos}
          toggleDescriptionModal={props.toggleDescriptionModal}
          index={2}
          style={{ width: '100%' }}
          name={'pontos-3'}
          onChange={(e)=>{
            props.handleSelectChange(e, 2)
          }}
          onAdd={async (value) => {
            const res = await props.onAdd(value, props.type)

            return res
          }}
          disabled={false}
          options={props.options}
        />

        </Col>
        <Col sm="8">
          <CampoTextArea
            className='feedback-text-area'
            onChange={e => props.handleTextChange(e, 2)}
            value={props.pontos[2].text}
            disabled={false}
          />
        </Col>
      </Row>
      </Col>
  )
}

export default QuestionPointsFeedback
