import React, { Component } from "react";
import PropTypes from "prop-types";

import ItemInstrumentoDashboard from "./ItemInstrumentoDashboard";
import { ItemInstrumento, ItemActions } from "./styles";
import { FaRegQuestionCircle } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { Tooltip } from "react-tippy";
import InstructionsModal from "../../../../components/InstructionsModal";

export default class ItemInstrumentoExpirado extends Component {
  static propTypes = {
    indice: PropTypes.number,
    instrumento: PropTypes.object.isRequired
  };

  state = {
    instructionsModal: { isOpen: false, instructions: '' }
  }

  toggleInstructionsModal = (instructionsText = '') => {
    this.setState({ instructionsModal: { isOpen: !this.state.instructionsModal.isOpen, instructions: instructionsText } })
  }

  render() {
    const { instrumento } = this.props;

    return (
      <>
        <InstructionsModal
          isOpen={this.state.instructionsModal.isOpen}
          modalHeader={'Ajuda'}
          modalBody={
            <div style={{ fontSize: 12 }}>
              <p dangerouslySetInnerHTML={{ __html: this.state.instructionsModal.instructions }}></p>
            </div>
          }
          onCancel={this.toggleInstructionsModal}
          toggle={this.toggleInstructionsModal}
        />
        <ItemInstrumento
          key={this.props.indice}
        >
          <ItemInstrumentoDashboard instrumento={instrumento} />
          <ItemActions>
            <Tooltip title="Atividade Expirada" size="small" position="top" arrow>
              <BiError size={23} color="#9F4848" id={`expirado-${instrumento._id}`} />
            </Tooltip>
            {instrumento.instructions && (
                <>
                    <Tooltip
                      title="Clique para ver a ajuda"
                      size="small"
                      position="top"
                      arrow

                    >
                      <FaRegQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => this.toggleInstructionsModal(instrumento.instructions.expired)} size={20} />
                    </Tooltip>
                </>
              )}
          </ItemActions>
        </ItemInstrumento>
      </>
    );
  }
}
