import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import "./css/CampoTextArea.css";
/**
 * O campo de textarea padrão do IAPP
 *
 * @export
 * @class CampoTextArea
 * @extends {Component}
 */
export default class CampoTextArea extends Component {
  render() {
    return (
      <Col sm="12" className="campo-text-area" style={{ padding: 0 }}>
        <textarea
          className={this.props.className}
          type="text"
          name={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          maxLength={250}
          value={this.props.value}
          required={this.props.required}
          style={{ backgroundColor: this.props.fundo }}
          disabled={this.props.disabled}
          tabIndex={this.props.tabIndex || 0}
        />
      </Col>
    );
  }
}
