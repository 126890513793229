import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Button, InputGroup, InputGroupAddon, Container, Row, Form } from 'reactstrap';
import { validateEmail } from '../../../controllers/Authenticate';
import { notifyError } from '../../../services/notificacoes';
import { BaseLogin, LogoHome } from "../../Assets/Assets";

import './styles.css';

const LogoUser = require('../../../assets/img/boneco_login.png');

/**
 * Página para validar o e-mail do respondente
 *
 * @export
 * @param {object} props
 */
export default function Authenticate(props) {
  const [email, updateEmail] = useState('');
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  function updateText(e) {
    updateEmail(e.target.value);
  }

  async function handleForm(e) {
    e.preventDefault();
    try {
      setLoadingSpinner(true);
      await validateEmail(
        email,
        id,
        props.history
      );
    } catch (err) {
      console.error(err)
      if (err && err.response && err.response.status === 403) return notifyError("Email incorreto");
      else if (err && err.response && err.response.status === 400) return notifyError("Preencha o campo de email");

      return notifyError("Não foi possível realizar login. Por favor, recarregue a página e tente novamente");
    } finally {
      setLoadingSpinner(false);
    }
  }

  const url = document.URL.split('/');
  const id = url[url.length - 1];
  return (
    <>
    <div className="app flex-row align-items-center">
        <BaseLogin>
          <div className="app flex-row align-items-center" id="inner-div">
            <br /> <br />
            <Container>
              <Row className="justify-content-center">
                <Col sm="8" className="text-center" offset="6">
                  <LogoHome
                    alt="logo"
                    src={require("../../../assets/img/logo_vertical.png")}
                  />
                  <br />
                  <Row>
                    <Col sm={{ size: 6, order: 2, offset: 3 }}>
                      <Form onSubmit={(e) => handleForm(e)}>
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="icons"
                          >
                            <img src={LogoUser} className="iconsImg" />
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="E-mail"
                            autoComplete="email"
                            name="email"
                            onChange={(e) => updateText(e)}
                            style={{
                              backgroundColor: "#5b654e",
                              color: "white",
                              borderColor: "#5b654e"
                            }}
                            required
                            className="input-login"
                          />
                        </InputGroup>
                        <br />
                        <Button
                          className="botao"
                          disabled={loadingSpinner}
                          style={{
                            backgroundColor: "#694e4a",
                            color: "white",
                            borderBox: "solid #694e4a"
                          }}
                        >
                          ACESSAR
                        </Button>

                        <br />
                        {loadingSpinner && (
                          <img src={require("../../../assets/img/spinner.gif")} />
                        )}
                        <br />
                      </Form>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <footer>
                    <small>
                      <b>Copyright© 2021 - Formare Tech.</b>
                      <br />
                      Todos os Direitos Reservados{" "}
                    </small>
                  </footer>
                </Col>
              </Row>
            </Container>
          </div>
        </BaseLogin>
      </div>
    </>
  );
}

Authenticate.propTypes = {
  history: PropTypes.object.isRequired
};
