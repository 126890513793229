import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash'
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import removeSpecials from "../../../../../utils/removeSpecials";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel, ButtonSave } from "../../../../Assets/Assets";

import './style.css'

const SearchableSelect = ({ options: propOptions, onChange, style, onAdd, pontos, index, toggleDescriptionModal }) => {
  const [filter, setFilter] = useState("");
  const [oldFilter, setOldFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState(propOptions)
  const [addLoading, setAddLoading] = useState(false)
  const [behaviourModal, setBehaviourModal] = useState(false)
  const [behaviourToInclude, setBehaviourToInclude] = useState('')

  const buttonRef = useRef();
  const anchorRef = useRef();

  // Filtrar opções com base na entrada do usuário
  const filteredOptions =
    filter.length >= 4
      ? options.filter(option =>
          removeSpecials(option.nome).toLowerCase().includes(removeSpecials(filter).toLowerCase())
        )
      : options;

  const handleSelect = (option) => {
    if (option.disabled) return; // Ignora opções desabilitadas
    setSelectedOption(option);
    setFilter(option.nome);
    setOldFilter(option.nome)
    setIsOpen(false);
    onChange(option);
  };

  useEffect(() => {
    let newOptions = [...propOptions]
    newOptions.map(o => {
      o.orderName = removeSpecials(o.nome.toLowerCase())
    })

    newOptions = _.orderBy(newOptions, ['orderName'], ['asc'])
    setOptions(newOptions)
  }, [propOptions])

  const toggleBehaviourModal = () => {
    if (behaviourModal) {
      setBehaviourToInclude('')
      setIsOpen(false)
    }

    setBehaviourModal(!behaviourModal)
  }

  return (
    <>
        <Modal
          size={'md'}
          isOpen={behaviourModal}
          toggle={toggleBehaviourModal}
        >
          <ModalHeader toggle={toggleBehaviourModal}>
            Deseja incluir um comportamento ?
          </ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            <span style={{ fontWeight: 'bold' }}>Comportamento: </span>
            <input className="feedback-input" style={{
              flex: 1,
              padding: "3px",
              marginLeft: 10,
              width: 200,
              border: "1px solid #ccc",
              borderRadius: "4px 0 0 4px",
              outline: "none",
            }} placeholder="Digite o comportamento..." type="text" maxLength={255} value={behaviourToInclude}
            onChange={(e) => {
              setBehaviourToInclude(e.target.value)
            }} />
          </ModalBody>
          <ModalFooter>
            <ButtonCancel style={{ width: 150 }} onClick={ () => {
            toggleBehaviourModal()
          } }>Cancelar</ButtonCancel>
          <ButtonSave style={{
            width: 150,
            opacity: !behaviourToInclude || behaviourToInclude.length < 4 || options.find(o => o.nome.toLowerCase() === behaviourToInclude.toLowerCase()) ? '50%' : '100%',
            cursor: !behaviourToInclude || behaviourToInclude.length < 4 || options.find(o => o.nome.toLowerCase() === behaviourToInclude.toLowerCase()) ? 'default' : 'pointer',
           }}
            onClick={async () => {
            if (!behaviourToInclude || behaviourToInclude.length < 4 || options.find(o => o.nome.toLowerCase() === behaviourToInclude.toLowerCase()) || addLoading) return

            if (onAdd) {
              setAddLoading(true)
              const res = await onAdd(behaviourToInclude);
              handleSelect(res)
              setAddLoading(false)
              toggleBehaviourModal()
            }
          }}>
            Incluir
          </ButtonSave>
          </ModalFooter>
        </Modal>
      <div style={{ position: "relative", width: "200px", ...style }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            className="feedback-input"
            type="text"
            value={filter}
            placeholder={oldFilter ? oldFilter : "Selecione ou digite um termo..."}
            onChange={(e) => {
              setFilter(e.target.value)
              setIsOpen(true)
            }}
            onClick={() => {
              setFilter('')
              setIsOpen(true)
            }}
            onBlur={(e) => {
              const relatedTarget = e.relatedTarget

              if (anchorRef && anchorRef.current && relatedTarget === anchorRef.current) return

              setIsOpen(false)
              setFilter(oldFilter)
            }}
            style={{
              flex: 1,
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px 0 0 4px",
              outline: "none",
            }}
          />
          {/* <Tooltip
            title="O termo informado não existe. Clique aqui para incluir, ou selecione outro na lista"
            size="small"
            position="top"
            style={{ display: !filter || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) ? 'none' : 'unset' }}
            arrow
          >
            <button
              ref={buttonRef}
              onClick={async () => {
                if (!filter || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) || addLoading) return

                if (onAdd) {
                  setAddLoading(true)
                  const res = await onAdd(filter);
                  handleSelect(res)
                  setAddLoading(false)
                }
              }}
              style={{
                padding: "8px 12px",
                opacity: !filter || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) ? '0%' : '100%',
                background: "#929c7c",
                color: "#fff",
                border: "none",
                borderRadius: "0 4px 4px 0",
                cursor: !filter || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) ? 'default' : "pointer",
                outline: "none",
              }}
            >
              +
            </button>
          </Tooltip> */}
          { pontos[index].description ?  <Tooltip
                      title="Descrição do comportamento"
                      size="small"
                      position="top"
                      arrow
                    ><FaRegQuestionCircle style={{ marginLeft: 5 }} onClick={() => toggleDescriptionModal(pontos[index].description)} cursor={'pointer'} size={15} /></Tooltip> : null }
        </div>
        {isOpen && (
          <ul
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              maxHeight: "150px",
              overflowY: "auto",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              zIndex: 1000,
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  style={{
                    padding: "8px",
                    cursor: option.disabled ? "not-allowed" : "pointer",
                    color: option.disabled ? "#999" : "#000",
                    backgroundColor:
                      selectedOption?.value === option.value ? "#f0f0f0" : "#fff",
                    borderBottom: "1px solid #eee",
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {option.nome}
                </li>
              ))
            ) : (
              <li style={{ padding: "8px", color: "#999" }}>Não encontrado</li>
            )}
          </ul>
        )}
        {
          !filter || filter.length < 4 || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) ? null :
              <button ref={anchorRef} onClick={async (e) => {
                e.preventDefault()
                if (!filter || filteredOptions.find(o => o.nome.toLowerCase() === filter.toLowerCase()) || addLoading) return

                toggleBehaviourModal()
              }} style={{ border: 'none', background: 'transparent', fontSize: 9, color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>Adicionar comportamento</button>
        }
      </div>
    </>
  );
};

export default SearchableSelect;
