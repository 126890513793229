import React, { Component, createRef } from "react";
import { Prompt } from "react-router";
import api, { regularApi } from "../../../services/api";
import { Button, Container, Row, Col } from "reactstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import withSubscription from "./hoc-instrumentos-assincronos";

import "./css/ColetaDeFeedback.css";

import { EditorState, ContentState, convertFromHTML } from "draft-js";

import LogoSvg from "../../../assets/img/logo.svg";
import { OpenInstrucoes, Header } from "iapp-design-system";
import { QuestionFeedback as Question } from './Components/QuestionFeedback.jsx';
import CustomSelect from './Components/CustomSelect/CustomSelect';
import QuestionPointsFeedback from './Components/QuestionPointsFeedback';
import { notifySuccess, notifyError } from "../../../services/notificacoes";
import InstrumentContainer from "../../components/InstrumentContainer";
import { SubHeader } from "./Components/Subheader";
import { ASYNCHRONOUS_INSTRUMENTS_IDS } from "../../../constants/instruments-ids";
import ActivityActions from "./Components/ActivityActions";
import { scrollToUnfinishedQuestion } from './utils/scrollToUnfinishedQuestion';
import InstructionsModal from "../../../components/InstructionsModal";

const questionEnunciates = [
  (participantName) =>
    `Nessa etapa você deve indicar quais os "3 a 5" pontos fortes do(a) ${participantName}, que reconhece como mais relevantes. Apresente exemplos.`,
  (participantName) =>
    `Nessa etapa  você deve indicar as oportunidades de desenvolvimento pessoal mais relevantes para o(a) ${participantName}. Apresente exemplos.`,
];

const Loading = () => <p>Loading...</p>;

class Feedback extends Component {
  state = {
    hasChanged: false,
    pontosFortesExemplos: [{
      selected: '',
      text: '',
      description: ''
    },
    {
      selected: '',
      text: '',
      description: ''
    },
    {
      selected: '',
      text: '',
      description: ''
    },],
    pontosFracosExemplos: [{
      selected: '',
      text: '',
      description: ''
    },
    {
      selected: '',
      text: '',
      description: ''
    },
    {
      selected: '',
      text: '',
      description: ''
    },],
    dados: [],
    comportamentos: [],
    sinonimos: [],
    optionsForte: [],
    optionsFraco: [],
    loading: true,
    textos: [],
    instructionsModal: {
      isOpen: false,
      instructions: ''
    }
  };

  constructor(props) {
    super(props);
  }

  questionRef1 = createRef(null)
  questionRef2 = createRef(null)

  async componentDidMount() {
    await this.props.validateAccess(
      localStorage.getItem("assincrono"),
      localStorage.getItem("instrumento")
    );
    const response = await regularApi.get(
      "api/instruments/674470ffc362653092b3c3cb"
    );
    const { name, tipo, _id, data } = response.data;
    let enunciado;
    if (data.length > 0) {
      enunciado = data[0].enunciado;
    }

    if (enunciado) {
      const participantName = localStorage.getItem("userName");

      enunciado = enunciado.replace(/{{nomeParticipante}}/g, participantName);
    }

    const resBehaviours = await api.get('/respondente/get-feedback-behaviours')

    const { comportamentos, sinonimos } = resBehaviours.data

    const optionsForte = []
    const optionsFraco = []

    if (comportamentos && comportamentos.length) {
      comportamentos.map(c => {
        let disabledForte = false
        let disabledFraco = false

        if (this.state.pontosFortesExemplos.find(p => p.selected === c._id)) disabledForte = true
        if (this.state.pontosFracosExemplos.find(p => p.selected === c._id)) disabledFraco = true

        const objForte = {
          valor: c._id,
          selected: false,
          nome: c.label,
          disabledForte
        }

        const objFraco = {
          valor: c._id,
          selected: false,
          nome: c.label,
          disabledFraco
        }

        if (c.type === 'strong') {
          optionsForte.push(objForte)
        } else if (c.type === 'weak') {
          optionsFraco.push(objFraco)
        }

      })
    }

    if (sinonimos && sinonimos.length) {
      sinonimos.map(c => {
        let disabledForte = false
        let disabledFraco = false

        if (this.state.pontosFortesExemplos.find(p => p.selected === c._id)) disabledForte = true
        if (this.state.pontosFracosExemplos.find(p => p.selected === c._id)) disabledFraco = true

        const objForte = {
          valor: c._id,
          selected: false,
          nome: c.label,
          disabledForte
        }

        const objFraco = {
          valor: c._id,
          selected: false,
          nome: c.label,
          disabledFraco
        }

        const comportamentoFinded = comportamentos.find(com => String(com._id) === String(c.behaviour_id))

        if (comportamentoFinded) {
          if (comportamentoFinded.type === 'strong') {
            optionsForte.push(objForte)
          } else if (comportamentoFinded.type === 'weak') {
            optionsFraco.push(objFraco)
          }
        }
      })
    }

    let textos0 = ''

    /*const dadosDb = response.data;
    const savedAnswers = await api.get(
      `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
        "assincrono"
      )}/${localStorage.getItem("instrumento")}/${localStorage.getItem(
        "projeto"
      )}`
    );

    let textos0 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[0] &&
      !savedAnswers.data.textos
    ) {
      textos0 = savedAnswers.data[0];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[0]
    ) {
      textos0 = savedAnswers.data.textos[0];
    }

    let textos1 = "";
    if (
      savedAnswers.data &&
      savedAnswers.data[1] &&
      !savedAnswers.data.textos
    ) {
      textos1 = savedAnswers.data[1];
    } else if (
      savedAnswers.data &&
      savedAnswers.data.textos &&
      savedAnswers.data.textos[1]
    ) {
      textos1 = savedAnswers.data.textos[1];
    }

    const storageName0 = `coletaEscolhas0-${localStorage.getItem('assincrono')}`;
    const storageName1 = `coletaEscolhas1-${localStorage.getItem('assincrono')}`;

    this.props.getStorageName([storageName0, storageName1]);

    if (sessionStorage.getItem(storageName0 || storageName1)) this.props.setHasChanged(true);

    if (sessionStorage.getItem(storageName0)) {
      const draft0 = JSON.parse(sessionStorage.getItem(storageName0));
      if (draft0.blocks && draftToHtml(draft0) !== '<p></p>')
        textos0 = draftToHtml(draft0);
    }

    if (sessionStorage.getItem(storageName1)) {
      const draft1 = JSON.parse(sessionStorage.getItem(storageName1));
      if (draft1.blocks && draftToHtml(draft1) !== '<p></p>')
        textos1 = draftToHtml(draft1);
    }*/

    this.setState({
      textos: [textos0].map(this.convertTextToEditorState),
      dados: [],
      comportamentos,
      sinonimos,
      optionsForte,
      optionsFraco,
      instrumento: {
        nome: name,
        enunciado: enunciado,
      },
      loading: false,
      hasChanged: false,
    });
  }

  getQuestionEnunciate = (participantName) => {
    return `Considera`
  }

  validate = () => {
    let valid = true

    if (this.state.pontosFortesExemplos.find(c => !c.selected) ||
    this.state.pontosFracosExemplos.find(c => !c.selected) ||
    this.state.pontosFortesExemplos.find(c => !c.text) ||
    this.state.pontosFracosExemplos.find(c => !c.text)) valid = false

    return valid
  }

  enviar = async () => {
    try {
      const isValid = this.validate()

      if (!isValid) return notifyError('Há questões que precisam ser preenchidas')

      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));

      const weaknesses = []

      this.state.pontosFracosExemplos.map(p => {
        let comportamentoId = ''
        let sinonimoId = ''
        let label = ''

        const comportamentoFinded = this.state.comportamentos.find(c => c._id === p.selected)

        if (comportamentoFinded) {
          label = comportamentoFinded.label
          comportamentoId = comportamentoFinded._id
        }
        else {
          const sinonimoFinded = this.state.sinonimos.find(c => c._id === p.selected)

          comportamentoId = sinonimoFinded.behaviour_id
          sinonimoId = sinonimoFinded._id
          label = sinonimoFinded.label
        }

        const obj = {
          behaviour_id: comportamentoId,
          label,
          synonymous_id: sinonimoId,
          justification: p.text
        }

        weaknesses.push(obj)
      })

      const strengths = []

      this.state.pontosFortesExemplos.map(p => {
        let comportamentoId = ''
        let sinonimoId = ''
        let label = ''

        const comportamentoFinded = this.state.comportamentos.find(c => c._id === p.selected)

        if (comportamentoFinded) {
          label = comportamentoFinded.label
          comportamentoId = comportamentoFinded._id
        }
        else {
          const sinonimoFinded = this.state.sinonimos.find(c => c._id === p.selected)

          comportamentoId = sinonimoFinded.behaviour_id
          sinonimoId = sinonimoFinded._id
          label = sinonimoFinded.label
        }

        const obj = {
          behaviour_id: comportamentoId,
          synonymous_id: sinonimoId,
          label,
          justification: p.text
        }

        strengths.push(obj)
      })

      const payload = {
        weaknesses,
        strengths,
        final_considerations: textos[0],
        finished: true
      }

      await api.put(`/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}/responder-feedback-feedforward`, {
            resposta: payload,
            projetoID: localStorage.getItem("projeto"),
          })

      notifySuccess("Suas respostas foram enviadas");
      this.props.setHasChanged(false);

      /*await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: {
              textos,
              finished: true,
            },
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess("Suas respostas foram enviadas");
          this.props.setHasChanged(false);
        });
      */
      this.props.history.goBack();
    } catch (err) {
      this.props.handleError(err);
    }
  };

  saveAnswer = async () => {
    try {
      const drafts = this.state.textos;
      const textos = drafts.map((draft) => draftToHtml(draft));
      await api
        .put(
          `/respondente/${localStorage.getItem("user")}/${localStorage.getItem(
            "assincrono"
          )}/${localStorage.getItem("instrumento")}`,
          {
            resposta: textos,
            projetoID: localStorage.getItem("projeto"),
          }
        )
        .then((e) => {
          notifySuccess(
            "Suas respostas foram salvas, você pode voltar no questionário a qualquer momento"
          );
        });
    } catch (err) {
      this.props.handleError(err);
    }
  };

  voltar = () => {
    this.props.history.goBack();
  };

  handleBack = () => {
    const { hasChanged } = this.state;
    return hasChanged;
  };
  convertTextToEditorState = (text) => {
    const blocksFromHtml = convertFromHTML(text || "<p></p>");
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
    );
  };
  handleDraftChange = (index) => (newEditorState) => {
    const updatedText = newEditorState;
    let newTexts = [...this.state.textos];

    newTexts = newTexts.map((text, idx) => {
      if (idx === index) {
        return updatedText;
      } else {
        return text;
      }
    });

    if (newTexts[0].blocks && draftToHtml(newTexts[0]) !== '<p></p>') {
      sessionStorage.setItem(`coletaEscolhas0-${localStorage.getItem('assincrono')}`, JSON.stringify(newTexts[0]));
    }

    this.props.setHasChanged(true);

    this.setState({ textos: newTexts });
  };

  handlePontosFortesSelectChange = (value, index) => {
    const newPontosFortesExemplos = [...this.state.pontosFortesExemplos]
    const newOptionsForte = [...this.state.optionsForte]

    newPontosFortesExemplos[index].selected = value.valor

    let comportamento = this.state.comportamentos.find(c => String(c._id) === String(value.valor))

    if (!comportamento) {
      const sinonimo = this.state.sinonimos.find(s => String(s._id) === String(value.valor))
      comportamento = this.state.comportamentos.find(c => String(c._id) === String(sinonimo.behaviour_id))
    }

    newPontosFortesExemplos[index].description = comportamento.description || ''

    newOptionsForte.map(o => {
      o.disabled = false
      o.selected = false
    })

    newPontosFortesExemplos.map(o => {
      const finded = newOptionsForte.find(f => f.valor === o.selected)
      if (finded) {
        finded.disabled = true
        finded.selected = true
      }
    })

    this.setState({ pontosFortesExemplos: newPontosFortesExemplos, optionsForte: newOptionsForte })

    this.props.setHasChanged(true);
  }

  handlePontosFracosSelectChange = (value, index) => {
    const newPontosFracosExemplos = [...this.state.pontosFracosExemplos]
    const newOptionsFraco = [...this.state.optionsFraco]

    newPontosFracosExemplos[index].selected = value.valor

    let comportamento = this.state.comportamentos.find(c => String(c._id) === String(value.valor))

    if (!comportamento) {
      const sinonimo = this.state.sinonimos.find(s => String(s._id) === String(value.valor))
      comportamento = this.state.comportamentos.find(c => String(c._id) === String(sinonimo.behaviour_id))
    }

    newPontosFracosExemplos[index].description = comportamento.description || ''

    newOptionsFraco.map(o => {
      o.disabled = false
      o.selected = false
    })

    newPontosFracosExemplos.map(o => {
      const finded = newOptionsFraco.find(f => f.valor === o.selected)
      if (finded) {
        finded.disabled = true
        finded.selected = true
      }
    })

    this.setState({ pontosFracosExemplos: newPontosFracosExemplos, optionsFraco: newOptionsFraco })

    this.props.setHasChanged(true);
  }

  handlePontosFortesTextChange = (event, index) => {
    const newPontosFortesExemplos = [...this.state.pontosFortesExemplos]

    newPontosFortesExemplos[index].text = event.target.value

    this.setState({ pontosFortesExemplos: newPontosFortesExemplos })

    this.props.setHasChanged(true);
  }

  handlePontosFracosTextChange = (event, index) => {
    const newPontosFracosExemplos = [...this.state.pontosFracosExemplos]

    newPontosFracosExemplos[index].text = event.target.value

    this.setState({ pontosFracosExemplos: newPontosFracosExemplos })

    this.props.setHasChanged(true);
  }

  handleAddBehaviour = async(value, type, index) => {
    const res = await api.post(`/respondente/${sessionStorage.getItem('respondenteId')}/create-feedback-behaviour`, {
      label: value,
      type
    })

    const optionsForte = [...this.state.optionsForte]
    const optionsFraco = [...this.state.optionsFraco]

    if (type === 'strong') {
      optionsForte.map(o => {
        o.selected = false
        o.disabled = false
      })
    } else if (type === 'weak') {
      optionsFraco.map(o => {
        o.selected = false
        o.disabled = false
      })
    }

    const { comportamento: c } = res.data

    const comportamentos = [...this.state.comportamentos, c]

    const objForte = {
      valor: c._id,
      selected: false,
      nome: c.label,
      disabledForte: false
    }

    const objFraco = {
      valor: c._id,
      selected: false,
      nome: c.label,
      disabledFraco: false
    }

    if (type === 'strong') optionsForte.push(objForte)
    else if (type === 'weak') optionsFraco.push(objFraco)

    this.setState({ comportamentos, optionsForte, optionsFraco })

    return objForte
  }

  toggleInstructionsModal = (instructionsText = '') => {
    const toSplit = instructionsText.includes('Descrição:') ? 'Descrição:' : ' Descrição '
    let newText = instructionsText.split(toSplit)
    if (newText[0] && newText[1]) newText = `<p>${newText[0]}</p>${newText[1]}`
    else newText = `<p>${newText[0]}</p>`

    this.setState({ instructionsModal: { isOpen: !this.state.instructionsModal.isOpen, instructions: newText } })
  }

  render() {
    const { loading, hasChanged, textos, instrumento } = this.state;
    let editorsInitalState = [...textos];
    let participantName = localStorage.getItem("userName");
    let enunciado = instrumento && instrumento.enunciado;
    window.onbeforeunload = (e) => {
      if (hasChanged) return true;
    };

    return (
      <InstrumentContainer vh='0vh'>
        <InstructionsModal
          isOpen={this.state.instructionsModal.isOpen}
          modalHeader={'Descrição'}
          modalBody={
            <div style={{ fontSize: 12 }}>
              <p dangerouslySetInnerHTML={{ __html: this.state.instructionsModal.instructions }}></p>
            </div>
          }
          onCancel={this.toggleInstructionsModal}
          toggle={this.toggleInstructionsModal}
        />
        <Prompt
          when={this.props.handleBack()}
          message="É possivel que suas alterações não tenham sido salvas."
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <SubHeader
              titulo="Feedback & Feedforward"
              instrucao={enunciado}
              idInstrumento={ASYNCHRONOUS_INSTRUMENTS_IDS.Feedback}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Row
                style={{ marginTop: 50 }}
              >
                  <Col sm="12">
                    <div ref={this.questionRef1}>
                      <QuestionPointsFeedback toggleDescriptionModal={(text='') => this.toggleInstructionsModal(text)} type={'strong'} onAdd={this.handleAddBehaviour} section={'Escolha os pontos admiráveis'} options={this.state.optionsForte} handleSelectChange={this.handlePontosFortesSelectChange} handleTextChange={this.handlePontosFortesTextChange} pontos={this.state.pontosFortesExemplos} title={`1. Quais os pontos admiráveis que você destaca em ${localStorage.getItem('userName')}? Apresente exemplos para cada escolha.`} />
                    </div>
                  </Col>
              </Row>
              <Row
                style={{ marginTop: 50 }}
              >
                  <Col sm="12">
                    <div ref={this.questionRef2}>
                      <QuestionPointsFeedback toggleDescriptionModal={(text='') => this.toggleInstructionsModal(text)} type={'weak'} onAdd={this.handleAddBehaviour} section={'Escolha os pontos a desenvolver'} options={this.state.optionsFraco} handleSelectChange={this.handlePontosFracosSelectChange} handleTextChange={this.handlePontosFracosTextChange} pontos={this.state.pontosFracosExemplos} title={`2. Quais os pontos que ${localStorage.getItem('userName')} precisa desenvolver? Apresente exemplos para cada escolha.`} />
                    </div>
                  </Col>
              </Row>
              <Row
                style={{ marginTop: 50 }}
              >
                {" "}
                {/* dá pra diminuir pra 100 sem ficar feio no mobile */}
                <Col sm="12">
                  <Question
                    number={0}
                    enunciate={this.getQuestionEnunciate(participantName)}
                    editorInitialState={editorsInitalState[0]}
                    ansOnChange={this.handleDraftChange(0)}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

        <ActivityActions
          back={() => this.voltar()}
          send={this.enviar.bind(this)}
          nextSend={() => scrollToUnfinishedQuestion([this.questionRef1, this.questionRef2], ['1.', '2.'], [this.state.pontosFortesExemplos, this.state.pontosFracosExemplos] , 0, false, true)}
        />
      </InstrumentContainer>
    );
  }
}

export default withSubscription(Feedback);
