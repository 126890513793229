import React, { Component } from "react";
import { Col } from "reactstrap";

import ErrorBoundary from "../../../../views/components/general/ErrorBoundary";
import ItemInstrumentoPendente from "./ItemInstrumentoPendente.jsx";
import ItemInstrumentoFinalizado from "./ItemInstrumentoFinalizado.jsx";
import ItemInstrumentoExpirado from "./ItemInstrumentoExpirado";
import ItemInstrumentoProximo from "./ItemInstrumentoProximo";
import ItemInstrumentoReport from "./ItemInstrumentoReport";
import ItemInstrumentoDinamica from "./ItemInstrumentoDinamica";
import ItemInstrumentoProva from './ItemInstrumentoProva';

import { ModuloAssincrono } from "../../../../services/Constantes";
import Loading from "../../../../services/Loading";
import api from "../../../../services/api";
import { Link } from "react-router-dom";
import { BsFillCalendarFill } from "react-icons/bs";
import { Tooltip } from "react-tippy";
import FinishedTextInstrument from "./FinishedTextInstrument";
import { formatDate } from '../../../../utils/index';
const { Nomes_Instrumentos } = ModuloAssincrono;

class ListaInstrumentos extends Component {
  atividadeTitles = {
    pendentes: "A serem realizadas",
    finalizados: "Finalizadas",
    expirados: "Expiradas",
    proximos: "Próximos Eventos",
  };

  instrumentoStatus = {
    pendentes: "pendente",
    finalizados: "finalizado",
    expirados: "expirado",
  };

  instrumentoButtonStyles = {
    pendentes: styles.pendentesButton,
    finalizados: styles.finalizadasButton,
    expirados: styles.expiradasButton,
    proximos: styles.proximosButton,
  };

  state = {
    modalFinalizado: false,
    resultado: "",
  };

  componentWillMount() {
    this.instrumentoButtonRef = React.createRef();
  }

  componentDidMount() {
    const titleStyle = getComputedStyle(this.instrumentoButtonRef.current);
    const titleTotalHeight = titleStyle.height;
    this.instrumentoButtonRef.current.style.top = `calc((${titleTotalHeight}) * -1 / 2)`;
  }

  async getModalSessao(idSessao) {
    const responseAssessments = await api.get(
      `/api/percepcoes?idSessao=${idSessao}`
    );

    this.setState({
      modalFinalizado: true,
      resultado: '',
    });
  }

  render() {
    const { instrumentos, tipoLista } = this.props;

    return (
      <>
        <Col style={styles.instrumentoContainer} sm="12" md="6" lg="3">
          <div>
            <div
              style={{
                ...styles.instrumentoButton,
                ...this.instrumentoButtonStyles[tipoLista],
                fontSize: 12,
                display: "flex",
                justifyContent: "space-between",
                cursor: 'default'
              }}
              ref={this.instrumentoButtonRef}
            >
              <span>{this.atividadeTitles[tipoLista]}</span>
              {tipoLista === "proximos" && (
                <>
                <Tooltip title="Meu calendário" size="small" position="left" arrow>
                  <Link to={"/participante/calendario"} style={{ color:"white", textDecoration: 'none' }}>
                    <BsFillCalendarFill size={18} />
                  </Link>
                </Tooltip>
                </>
              )}
            </div>
          </div>
          {!this.props.loadingAtividades ? (
            instrumentos && instrumentos.length ? (
              instrumentos.map((instrumento, index) => {
                if (instrumento) {
                  switch (tipoLista) {
                    case "pendentes":
                      return (
                        <ErrorBoundary key={index}>
                          <ItemInstrumentoPendente
                            indice={index}
                            instrumento={instrumento}
                            play={this.props.playInstrumento}
                            userCanAnswer={instrumento.userCanAnswer}
                            openRespondentModal={this.props.openRespondentModal}
                          />
                        </ErrorBoundary>
                      );

                    case "finalizados":
                      let isFinaliza;
                      let isAssessment;
                      if (instrumento.assessments) {
                        if (instrumento.userCanSeeResults) {
                          isFinaliza = true;
                          isAssessment = true;
                        } else {
                          isFinaliza = false;
                          isAssessment = false;
                        }
                      } else if (
                        // instrumento.isExpirado(null) === false &&
                        (instrumento.name ===
                          Nomes_Instrumentos.HISTORIA_DE_VIDA ||
                          instrumento.name ===
                            Nomes_Instrumentos.PROJETOS_ATUAIS ||
                          instrumento.name ===
                            Nomes_Instrumentos.COLETA_DE_FEEDBACK ||
                          instrumento.name ===
                            Nomes_Instrumentos.FEEDBACK)
                      ) {
                        isFinaliza = true;
                      }

                      if (instrumento.name === ModuloAssincrono.Nomes_Instrumentos.HISTORIA_DE_VIDA) {
                        return (
                          <FinishedTextInstrument
                            indice={index}
                            instrumento={instrumento}
                            userCanSeeResults={instrumento.userCanSeeResults}
                            play={() => this.props.playInstrumento(instrumento)}
                            dateExpired={instrumento.isExpirado(null)}
                            showResults={() => {
                              if (!isAssessment) {
                                return this.props.showResults(instrumento);
                              } else {
                                this.getModalSessao(instrumento._id);
                              }
                            }}
                            openRespondentModal={this.props.openRespondentModal}
                          />
                        );
                      }
                      if (instrumento.name === ModuloAssincrono.Nomes_Instrumentos.PROJETOS_ATUAIS) {
                        return (
                          <FinishedTextInstrument
                            indice={index}
                            instrumento={instrumento}
                            userCanSeeResults={instrumento.userCanSeeResults}
                            play={() => this.props.playInstrumento(instrumento)}
                            dateExpired={instrumento.isExpirado(null)}
                            showResults={() => {
                              if (!isAssessment) {
                                return this.props.showResults(instrumento);
                              } else {
                                this.getModalSessao(instrumento._id);
                              }
                            }}
                            openRespondentModal={this.props.openRespondentModal}
                          />
                        );
                      }

                      return (
                        <ErrorBoundary key={index}>
                          <ItemInstrumentoFinalizado
                            indice={index}
                            instrumento={instrumento}
                            // showPlayButton={
                            //   isFinaliza
                            // }
                            userCanSeeResults={instrumento.userCanSeeResults}
                            play={() => this.props.playInstrumento(instrumento)}
                            showResults={() => {
                              if (!isAssessment) {
                                return this.props.showResults(instrumento);
                              } else {
                                this.getModalSessao(instrumento._id);
                              }
                            }}
                            openRespondentModal={this.props.openRespondentModal}
                          />
                        </ErrorBoundary>
                      );

                    case "expirados":
                      return (
                        <ErrorBoundary key={index}>
                          <ItemInstrumentoExpirado
                            indice={index}
                            instrumento={instrumento}
                          />
                        </ErrorBoundary>
                      );
                  }
                }
              })
            ) : (
              <></>
            )
          ) : (
            <>
              <Loading wrapperStyle={{ backgroundColor: "#fff" }}></Loading>
            </>
          )}
          {tipoLista == "proximos" && (
            <>
              <ErrorBoundary>
                {this.props.instrumentos &&
                  this.props.instrumentos.map((instrumento) => (
                    <ItemInstrumentoProximo instrumento={instrumento} />
                  ))}
                {/* {this.state.modalFinalizado ? (
                  <ModalResultadoAssessment
                    isOpen={this.state.modalFinalizado}
                    toggle={() => {
                      this.setState({ modalFinalizado: false });
                    }}
                    assessments={this.state.resultado}
                  />
                ) : (
                  ""
                )} */}
              </ErrorBoundary>
            </>
          )}

          { tipoLista === 'finalizados' && this.props.reports && (
            <>
              {this.props.reports.map((report) => (
                <ErrorBoundary key={report._id}>
                  <ItemInstrumentoReport
                    configId={report._id}
                    nome={report.name}
                    data={formatDate(report.date)}
                    projectName={report.projectName}
                    projectId={report.projectId}
                    instrumentsPermissions={report.instrumentsPermissions}
                    showResultsReport={this.props.showResultsReport}
                  />
                </ErrorBoundary>
              ))}
            </>
          ) }

          {tipoLista == "finalizados" && this.props.dinamicas && (
            <>
              {this.props.dinamicas.map((dinamica) => (
                <ErrorBoundary key={dinamica._id}>
                  <ItemInstrumentoDinamica
                    id={dinamica._id}
                    nome={dinamica.nome}
                    data={dinamica.data}
                    projectName={dinamica.projectName}
                    projectId={dinamica.projectId}
                    instrumentsPermissions={dinamica.instrumentsPermissions}
                    showResultsDynamic={this.props.showResultsDynamic}
                  />
                </ErrorBoundary>
              ))}
            </>
          )}

          {tipoLista == "finalizados" && this.props.provas && (
            <>
              {this.props.provas.map((prova) => (
                <ErrorBoundary key={prova._id}>
                  <ItemInstrumentoProva
                    id={prova._id}
                    nome={prova.nome}
                    data={prova.datas && prova.datas.dataFim}
                    projectName={prova.projectName}
                    projectId={prova._idProjeto}
                    allowShowResults={prova.allowShowResults}
                    showResultsProof={this.props.showResultsProof}
                  />
                </ErrorBoundary>
              ))}
            </>
          )}
        </Col>
      </>
    );
  }
}

const styles = {
  instrumentoContainer: {
    // border: "1px solid #c9c9c9"
  },
  instrumentoButton: {
    padding: "10px 25px 10px 25px",
    marginBottom: "10px",
    color: "white",
    cursor: "context-menu",
  },
  pendentesButton: {
    background: "#002a4b",
  },
  finalizadasButton: {
    background: "#3A6543",
  },
  expiradasButton: {
    background: "#9F4848",
  },
  proximosButton: {
    background: "#777",
  },
  container: {
    display: "flex",
  },
  justifyCenter: {
    justifyContent: "center",
  },
};

export default ListaInstrumentos;
