import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Row } from 'reactstrap';

import api from '../../../services/api';
import {regularApi} from '../../../services/api';
import { BaseProjeto } from "../../Assets/Assets";
import ListaInstrumentos from './components/ListaInstrumentos';
import { Instrumento } from '../../components/ListInstruments';
import Loading from '../../../services/Loading';
import SubHeader from "./components/SubHeader";
import {
  convertToDateTimeString,
  dateIsBefore,
  formatURL,
} from '../../../utils';
import removeSpecials from '../../../utils/removeSpecials';
import {capitalizeFirstLetterEachWord} from '../../../utils/masks';
import { notifyError } from '../../../services/notificacoes';
import { msgNoPermission, errorCode_NO_CREDITS } from '../../../services/Constantes';
// import './css/ListInstruments.css';
/**
 * Lista os instrumentos que podem ser respondidos
 *
 * @export
 * @class ListInstruments
 * @extends {Component}
 */
class ListInstruments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instrumentosPendentes: [],
      instrumentosFinalizados: [],
      instrumentosExpirados: [],
      estilosLiderancaUsers: [],
      loadingAtividades: false,
      dinamicas: [],
      reports: [],
      provas: [],
      textInstrucao: `
      <p>Você está na área do respondente. Aqui você encontrará questionários e formulários a serem respondidos para alguém que solicitou a sua visão sobre ele.</p>
      <p>Sua informação se constitui em um material riquíssimo para contribuir no desenvolvimento profissional do solicitante.</p>
      <p>Seu nome está mantido apenas para controle de resposta e não será apresentada para o participante que solicitou sua visão. Por outro lado, o conteúdo de seu formulário será apresentado na íntegra para o participante solicitante.</p>
      <strong>ATIVIDADES</strong>
      <p><strong>A serem realizadas</strong> - Questionários que você ainda não finalizou e que estão dentro do prazo de entrega. Para acessar o questionário, clique no ícone do "play" &nbsp <i class="fa fa-play"></i>.     A instrução referente ao instrumento, você encontrará assim que iniciar o instrumento.</p>
      <p><strong>Finalizadas</strong> - Questionário que você já finalizou.</p>
      <p><strong>Expiradas</strong> - Questionários que não foram finalizados e o prazo para preenchimento foi encerrado. Você não poderá mais fazê-los.</p>

      <p>Observação: As atividades enviadas, não poderão ser modificadas posteriormente, mesmo que o prazo não tenha terminado, dessa forma tire um tempo adequado para responder os questionários.</p>
    `,
      loaded: false,
    };
  }

  getInstruments = async () => {
    const res = await api.get('/respondente/get-activities');

    return res;
  };

  isInstrumentExpired(instrument) {
    try {
      let { dataFim } = instrument.datas;
      let { horarioFim } = instrument.horarios;
      const hoje = new Date();

      let tempoFimDateString = convertToDateTimeString(dataFim, horarioFim);

      if (dateIsBefore(new Date(tempoFimDateString), hoje) === true)
        return true;
      else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getExpiredInstruments(instruments) {
    return instruments.filter(instrument => this.isInstrumentExpired(instrument) === true);
  }

  responder = async (instrumento) => {
    try {
      const { idUser, nomeUser } = instrumento;
      const idGrp = instrumento.idGrupo;
      const idInstrumento = instrumento._id;

      const res = await regularApi.get(`api/instruments/instrument-play/${idInstrumento}?userId=${idUser}&assincId=${idGrp}`)

      localStorage.setItem('instrumento', idInstrumento);
      localStorage.setItem('user', idUser);
      localStorage.setItem('userName', capitalizeFirstLetterEachWord(nomeUser.toLowerCase()));
      localStorage.setItem('assincrono', idGrp);
      localStorage.setItem('projeto', instrumento.projectId);

      const estilosUsers = this.state.estilosLiderancaUsers.filter(u => u.groupId === idGrp);

      sessionStorage.setItem('estilosLiderancaUsers', JSON.stringify(estilosUsers));
      this.props.history.push(`/${formatURL(instrumento.name)}`);
    } catch (error) {
      console.log("error", error)

      let msg = 'Ocorreu um erro. Tente novamente.'
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === errorCode_NO_CREDITS) msg = `${msgNoPermission} entre em contato com o seu consultor e informe o código ${errorCode_NO_CREDITS}`

      return notifyError(msg)
    }
  }

  convertBrDateString(date, hour) {
    if (!date) return;
    const dateSplit = date.split('/');
    const year = dateSplit[2];
    const month = dateSplit[1];
    const day = dateSplit[0];

    let toReturnDate = `${month}-${day}-${year}`

    if (hour) {
      toReturnDate = `${year}-${month}-${day}T${hour}.000Z`;
      return toReturnDate;
    }


    return new Date(toReturnDate);
  }

  orderAsyncCards(assyncCards) {
    const assyncGrouped = _.groupBy(assyncCards, (b) => { return b.rawDate });
    for (let k in assyncGrouped) {
      assyncGrouped[k] = _.orderBy(assyncGrouped[k], ['treatedName'], ['asc']);

      let top = new Date(assyncGrouped[k][0].rawDate);
      for (const o of assyncGrouped[k]) {
        const date = new Date(o.rawDate);
        if (date > top) top = date;
      }

      assyncGrouped[k].biggestDate = top;
    }

    const toReturnArray = [];
    const assyncCardsOrdered = _.orderBy(assyncGrouped, ['biggestDate'], ['asc']);

    for (const o of assyncCardsOrdered) {
      delete o.biggestDate;
      toReturnArray.push(...o);
    }

    return toReturnArray;
  }

  async componentDidMount() {
    this.setState({ loadingAtividades: true });

    sessionStorage.removeItem('estilosLiderancaUsers');

    this.getInstruments()
      .then(async (responseInstruments) => {

        const instruments = responseInstruments.data;
        let pendingInstruments = [];
        let expiredInstruments = [];
        let finishedInstruments = [];

        instruments.map(instrument => {
          const isExpired = this.isInstrumentExpired(instrument);

          if (isExpired && !instrument.respAnswer) {
            expiredInstruments.push(instrument);
          } else if (instrument.respAnswer) {
            if (instrument.finishedAt) instrument.rawDate = new Date(instrument.finishedAt);
            finishedInstruments.push(instrument);
          } else {
            pendingInstruments.push(instrument);
          }
        });

        pendingInstruments.map(p => {
          p.rawDate = this.convertBrDateString(p.datas.dataFim);
          p.treatedName = p.name && removeSpecials(p.name.toLowerCase());
        });
        expiredInstruments.map(p => {
          p.rawDate = this.convertBrDateString(p.datas.dataFim);
        });

        const users = [];
        const pendingEstilosInstruments = [];
        const finishedEstilosInstruments = [];
        const expiredEstilosInstruments = [];

        const promises = pendingInstruments.map(async i => {
          if (!i.finishedAt && i.name && i.name.toLowerCase().includes('liderança')) {
            const res = await regularApi.get(`/v3/users/${i.idUser}/get-user-register-by-id?projectId=${i.projectId}`);

            const { user } = res.data;

            users.push({
              id: i.idUser,
              nome: capitalizeFirstLetterEachWord(i.nomeUser.toLowerCase()),
              foto: user.project.foto,
              projectId: i.projectId,
              groupId: i.idGrupo,
            });

            const finded = pendingEstilosInstruments.find(e => e.idGrupo === i.idGrupo);

            if (!finded) {
              pendingEstilosInstruments.push({ idGrupo: i.idGrupo, count: 1 });
            } else {
              finded.count = finded.count + 1;
            }
          }
        });

        await Promise.all(promises);

        pendingEstilosInstruments.map(i => {
          if (i.count > 1) {
            const instFinded = pendingInstruments.find(e => e.idGrupo === i.idGrupo && e.name.toLowerCase().includes('liderança'));

            if (instFinded) {
              const newInst = { ...instFinded, nomeUser: 'Diversos' }

              pendingInstruments = pendingInstruments.filter(e => {
                if (e.name.toLowerCase().includes('liderança')) {
                  if (e.idGrupo === i.idGrupo) return false;
                  else return true
                }

                return true;
              });

              pendingInstruments.push(newInst);
            }
          }
        });

        finishedInstruments.map(i => {
          if (i.name && i.name.toLowerCase().includes('liderança')) {
            const finded = finishedEstilosInstruments.find(e => e.idGrupo === i.idGrupo);

            if (!finded) {
              finishedEstilosInstruments.push({ idGrupo: i.idGrupo, count: 1 });
            } else {
              finded.count = finded.count + 1;
            }
          }
        });

        finishedEstilosInstruments.map(i => {
          if (i.count > 1) {
            const instFinded = finishedInstruments.find(e => e.idGrupo === i.idGrupo && e.name.toLowerCase().includes('liderança'));

            if (instFinded) {
              const newInst = { ...instFinded, nomeUser: 'Diversos' }

              finishedInstruments = finishedInstruments.filter(e => {
                if (e.name.toLowerCase().includes('liderança')) {
                  if (e.idGrupo === i.idGrupo) return false;
                  else return true
                }

                return true;
              });

              finishedInstruments.push(newInst);
            }
          }
        });

        expiredInstruments.map(i => {
          if (i.name && i.name.toLowerCase().includes('liderança')) {
            const finded = expiredEstilosInstruments.find(e => e.idGrupo === i.idGrupo);

            if (!finded) {
              expiredEstilosInstruments.push({ idGrupo: i.idGrupo, count: 1 });
            } else {
              finded.count = finded.count + 1;
            }
          }
        });

        expiredEstilosInstruments.map(i => {
          if (i.count > 1) {
            const instFinded = expiredInstruments.find(e => e.idGrupo === i.idGrupo && e.name.toLowerCase().includes('liderança'));

            if (instFinded) {
              const newInst = { ...instFinded, nomeUser: 'Diversos' }

              expiredInstruments = expiredInstruments.filter(e => {
                if (e.name.toLowerCase().includes('liderança')) {
                  if (e.idGrupo === i.idGrupo) return false;
                  else return true
                }

                return true;
              });

              expiredInstruments.push(newInst);
            }
          }
        });


        const pendingInstrumentsOrdered = this.orderAsyncCards(pendingInstruments);
        const finishedInstrumentsOrdered = _.orderBy(finishedInstruments, ['rawDate'], ['desc']);
        const expiredInstrumentsOrdered = _.orderBy(expiredInstruments, ['rawDate'], ['desc']);

        this.setState({
          instrumentosPendentes: pendingInstrumentsOrdered,
          instrumentosExpirados: expiredInstrumentsOrdered,
          instrumentosFinalizados: finishedInstrumentsOrdered,
          loaded: true,
          loadingAtividades: false,
          estilosLiderancaUsers: users
        });
      })
      .catch(err => console.error(err))
      .finally(_ => this.setState({
        loaded: true,
        loadingAtividades: false
      }));
  }

  render() {
    const {
        instrumentosPendentes,
        instrumentosExpirados,
        instrumentosFinalizados,
        provas,
        dinamicas,
        reports,
        loadingAtividades,
        loaded
      } = this.state;

      if (loadingAtividades) return <Loading />

      return (
        <div className="appster">

        <BaseProjeto style={{ backgroundColor: "#fff" }}>
          {/* <Header nome={nome} />  VER DPS*/}
          <div>
            <br />
            <Row>
              {/* {this.state.respostasAssincronoParticipante ? (
                <ModalResultadosParticipante
                  isOpen={this.state.modalIndividual}
                  toggle={this.toggleIndividual}
                  idProjeto={this.state.modalResultados.idProjeto}
                  idParticipante={this.state.modalResultados.idParticipante}
                  participantViewResults={true}
                  idAssincrono={this.state.modalResultados.idAssincrono}
                  idAssincronoInstrumento={
                    this.state.modalResultados.resultadosAssincronoIdInstrumento
                  }
                  resultadosIdDinamica={
                    this.state.modalResultados.resultadosIdDinamica
                  }
                  resultadosIdReport={
                    this.state.modalResultados.resultadosIdReport
                  }
                  resultadosIdProva={
                    this.state.modalResultados.resultadosIdProva
                  }
                  resultadosIdReport={
                    this.state.modalResultados.resultadosIdReport
                  }
                />
              ) : null} */}
              <SubHeader
                titulo="Minhas Atividades"
                instrucao={this.state.textInstrucao}
                showTitle={false}
                startClosed={true}
              />
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <ListaInstrumentos
                tipoLista="pendentes"
                instrumentos={instrumentosPendentes}
                resumo={this.resumo}
                playInstrumento={this.responder}
                openRespondentModal={this.toggleRespondenteModal}
                loadingAtividades={loadingAtividades}
              />
              <ListaInstrumentos
                tipoLista="finalizados"
                instrumentos={instrumentosFinalizados}
                resumo={this.resumo}
                playInstrumento={this.responder}
                openRespondentModal={this.toggleRespondenteModal}
                showResults={this.showResults}
                loadingAtividades={loadingAtividades}
                dinamicas={dinamicas}
                reports={reports}
                provas={provas}
                showResultsReport={this.showResultsReport}
                showResultsDynamic={this.showResultsDynamic}
                showResultsProof={this.showResultsProof}
              />
              <ListaInstrumentos
                tipoLista="expirados"
                instrumentos={instrumentosExpirados}
                resumo={this.resumo}
                playInstrumento={this.responder}
                loadingAtividades={loadingAtividades}
              />
              {/* <ListaInstrumentos
                tipoLista="proximos"
                instrumentos={this.state.proximos}
                resumo={this.resumo}
                playInstrumento={this.playInstrumento}
                loadingAtividades={loadingAtividades}
              /> */}
              {/* <ProximosEventos
                proximos={this.state.proximos}
              /> */}
            </Row>
          </div>
        </BaseProjeto>
        </div>
        // <div className='list-instruments' style={{ marginTop: 50 }}>
        //   {instrumentos.map(instrumento => (
        //     !instrumento.respAnswer ?
        //     (<div>
        //       <hr />
        //       <Instrumento
        //         instrumento={instrumento}
        //         history={this.props.history}
        //       />
        //     </div>) : null
        //   ))}
        // </div>
      );
  }
}

export default withRouter(ListInstruments);
