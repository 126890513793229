import styled from "styled-components";

export const BaseLogin = styled.div`
  background-color: #012a4a;
  color: white;
  width: 100vw;
  button {
    font-size: 16px;
  }
  .inputLogin {
    background: #001b2f;
    border: 0px;
    height: 40px;
    font-size: 16px !important;
    color: white;
    &::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 16px;
    }
  }
  .before span {
    background: #f0c219;
    border: 0px;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 10px;
  }
`;

export const LogoHome = styled.img`
  width: 150px;
  margin: 50px;
`;

export const BaseProjeto = styled.div`
  background: #f7f8f8;
  width: 100%;
  padding: 20px;
`;

export const CircularButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  padding: 5px 5px;

  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #f7f8f8;
  }
`;

export const ButtonCancel = styled.button`
  background: ${(props) => (props.yellow ? '#f0c219' : '#db0308')};
  color: ${(props) => (props.yellow ? '#012a4a' : '#fff')};
  border: 0px;
  font-size: 12px;
  width: ${(props) => (props.width || '200px')};
  margin: 20px 0px 20px 0px;
  padding: ${(props) => (props.padding || '10px')};
  border-radius: 5px;
`;

export const ButtonSave = styled.button`
  font-size: 12px;
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  margin: 10px;

  :focus {
    outline: 0;
  }
`;
